import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import { loreData } from "@util/whelpsData"
import { RightArrow, LeftArrow, ButtonGroup } from "./_util"
import "./Lore.scss"

const responsive = {
  mobile: {
    breakpoint: { max: 10000, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
}

export default function Lole() {
  return (
    <>
      <div className="bg-secondary-whelps">
        <div className="container mx-auto">
          <div>
            <div className="font-lemon text-primary-whelps text-2xl md:text-4xl tracking-wider text-center my-16">
              Where do Dragons Come From?
            </div>
            <div className="font-roboto-light text-light-whelps text-white max-w-5xl px-5 text-lg leading-8 mx-auto my-10 md:my-16">
              <div className="mt-6">
                While there are many theories about the precise origin of
                dragonkind, we cannot fully confirm any of them without
                travelling back in time to the very dawn of human civilization.
                What we do know is that in archaeological records—from
                well-hidden cave paintings to ancient cuneiform stelae—dragons
                are present, and can be seen living in community with humans and
                as our implacable—and nearly invincible—foes.
              </div>
              <div className="mt-6">
                At the dawn of human history dragons were , apparently, nearly
                everywhere: each town of any size had at least one dragon,
                mighty in defence of their chosen community, or crucial in
                clearing land for farming, or wise in civil administration and
                judgment. As we know, this time has long passed, and while wild
                dragons have always maintained their modestly-sized populations,
                few modern humans have ever laid eyes on them.
              </div>
              <div className="mt-6">
                Thus we have famous songs like “The Dragons And The Kraken,”
                telling the story of an epic battle between a gargantuan kraken
                and a band of young Tidal dragons as witnessed by a sailor blown
                off course into uncharted waters. Wanderers and adventurers
                might have seen Inferno dragons darting out of the smoke and ash
                clouds of volcanic explosions, and chance discoveries of a
                viable dragon’s egg have sparked wars and shifted the balance of
                power on continents. Many kingdoms mark historical periods with
                names like “The Era of The Wise Dragon” or “The Inferno Age”,
                underscoring how rare and special a dragon living among humans
                has been in the past five thousand years.
              </div>
              <div className="mt-6">
                But now, everything has changed. Deep in catacombs and caves,
                nestled among the roots of great trees, jumbled up with the
                driftwood after violent storms, scattered in steaming magma
                fields, poking out of the snow on mountain crags, and even
                tucked into the machine-works of great engines of science and
                industry, we are discovering them: the eggs. So far, over seven
                thousand of them have been discovered, by peasants and princes
                alike. As we incubate them in our hearths, our river-mills and
                our mausoleums, we wait to see how our WhelpS will evolve, and
                to learn the shape of our new Era of Dragons.
              </div>
            </div>
          </div>

          <div>
            <img src="/line.png" className="mx-auto" alt="" />
          </div>

          <div className="my-12 md:my-20 pb-6 md:pb-0">
            <Carousel
              swipeable
              draggable
              responsive={responsive}
              infinite={true}
              className="lore-carousel pb-28 md:pb-24"
              customLeftArrow={<LeftArrow />}
              customRightArrow={<RightArrow />}
              customButtonGroup={<ButtonGroup />}
            >
              {loreData.map((item, index) => (
                <div className="carousel-item pt-0 md:pt-28" key={index}>
                  <div className="font-lemon text-primary-whelps text-2xl md:text-4xl tracking-wider text-center mt-16">
                    {item.loreTitle}
                  </div>

                  <div className="font-roboto-light text-light-whelps text-white max-w-5xl px-0 md:px-5 text-lg leading-8 mx-auto my-8">
                    {item.loreContent.map((dt, idx) => (
                      <div className="mt-6" key={idx}>
                        {dt}
                      </div>
                    ))}
                  </div>

                  <img src={item.loreBg} className="mx-auto" alt="" />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  )
}
