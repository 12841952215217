// Core React
import React from "react"
import Layout from "@components/layout/Layout/Layout"
import Lore from "@components/partials/Lore"

// Render
const LorePage = () => (
  <Layout
    title="Lore || WhelpS NFT"
    contentClassName="px-0 max-w-full bg-secondary-whelps"
    page="lore"
  >
    <Lore />
  </Layout>
)
export default LorePage
