import React from "react"

const activeSlides = [
  {
    activeImg: "/slideDragons/dragon-inferno.png",
    activeTitle: "Inferno",
  },
  {
    activeImg: "/slideDragons/dragon-trakkor.png",
    activeTitle: "Trakkor",
  },
  {
    activeImg: "/slideDragons/dragon-tidal.png",
    activeTitle: "Tidal",
  },
  {
    activeImg: "/slideDragons/dragon-gravestone.png",
    activeTitle: "Gravestone",
  },
  {
    activeImg: "/slideDragons/dragon-zephyr.png",
    activeTitle: "Zephyr",
  },
  {
    activeImg: "/slideDragons/dragon-ironlung.png",
    activeTitle: "Ironlung",
  },
]

export const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  let {
    carouselState: { currentSlide, totalItems },
  } = rest
  totalItems = totalItems - 4

  return (
    <div className="absolute left-0 top-0 z-30 w-full hidden md:block">
      <div className="text-primary flex justify-center items-center">
        {[...Array(totalItems).keys()].map((dt, idx) => {
          if (currentSlide === 1) {
            currentSlide = totalItems
          }

          return (
            <div
              key={dt}
              onClick={() => goToSlide(dt + 2)}
              className={`rounded-full cursor-pointer mx-4 ${
                currentSlide - 1 === dt + 1 ? "active" : ""
              }`}
            >
              <img
                src={activeSlides[idx].activeImg}
                alt=""
                className="mx-auto"
              />
              <div
                className={`font-roboto-bold ${
                  currentSlide - 1 === dt + 1
                    ? "text-primary-whelps"
                    : "text-white"
                } text-xl leading-8 text-center mt-6`}
              >
                {activeSlides[idx].activeTitle}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const LeftArrow = ({ onClick, ...rest }) => {
  let {
    carouselState: { currentSlide, totalItems },
  } = rest
  totalItems = totalItems - 4

  if (currentSlide === 0) currentSlide = 2
  if (currentSlide === 1) currentSlide = 7
  if (currentSlide === 2) currentSlide = totalItems + 2

  return (
    <div className="custom-arrow custom-arrow__left" onClick={onClick}>
      <div className="flex items-center justify-center p-3 bg-black rounded-2xl">
        <img src="/lore-arrow/icon-previous.png" width="28" alt="" />
        <img
          src={activeSlides[currentSlide - 3].activeImg}
          className="ml-2 h-9 md:h-12"
          alt=""
        />
      </div>
      <div
        className={`font-roboto-bold text-white text-xl leading-8 text-center mt-3 md:mt-5`}
      >
        {activeSlides[currentSlide - 3].activeTitle}
      </div>
    </div>
  )
}

export const RightArrow = ({ onClick, ...rest }) => {
  let {
    carouselState: { currentSlide, totalItems },
  } = rest
  totalItems = totalItems - 4

  if (currentSlide === 0) currentSlide = 8
  if (currentSlide === 7) currentSlide = 1
  if (currentSlide === 8) currentSlide = currentSlide - totalItems

  return (
    <div className="custom-arrow custom-arrow__right" onClick={onClick}>
      <div className="flex items-center justify-center p-3 bg-black rounded-2xl">
        <img
          src={activeSlides[currentSlide - 1].activeImg}
          className="ml-2 h-9 md:h-12"
          alt=""
        />
        <img src="/lore-arrow/icon-next.png" width="28" alt="" />
      </div>
      <div
        className={`font-roboto-bold text-white text-xl leading-8 text-center mt-3 md:mt-5`}
      >
        {activeSlides[currentSlide - 1].activeTitle}
      </div>
    </div>
  )
}
